




import {Vue, Component} from 'vue-property-decorator';
import Divider from '../Divider.vue';

@Component({
  components: {
    Divider,
  },
})
export default class DetailDivider extends Vue {
}
